import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  description?: string
  title?: string
}

export const PolicyParagraph = memo(function PolicyParagraph({
  description,
  title,
}: Props) {
  if (!description || !title) {
    return null
  }

  return (
    <Container>
      <Head>{title ? <Title>{title}</Title> : null}</Head>

      <Wrapper>
        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section``

const Head = styled.div`
  background: ${({ theme }) => theme.colors.variants.primaryLight};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 16rem 10vw 9.1875rem;

  @media (max-width: 1023px) {
    padding: 8.5rem 1.3125rem 4.375rem;
  }
`

const Wrapper = styled.div`
  margin: 6.25rem auto 11.25rem;
  padding: 0 10vw;

  @media (max-width: 1023px) {
    margin-top: 5.3125rem;
    margin-bottom: 8.5rem;
    padding: 0 1.3125rem;
  }
`

const Title = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4.0625rem;
  line-height: 4.6875rem;

  @media (max-width: 1023px) {
    font-size: 2.1875rem;
    line-height: 2.8125rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  line-height: 2.125rem;
  margin-top: 1.875rem;

  a {
    color: ${({ theme }) => theme.colors.variants.primaryLight};
    text-decoration: underline;
  }
  p {
    margin-block-end: 1em;
  }
  ul {
    margin-bottom: 1em;
    padding: inherit;
    li {
      padding-left: 1.25rem;
      position: relative;
      &:before {
        content: '';
        width: 0.5rem;
        height: 0.5rem;
        background: ${({ theme }) => theme.colors.variants.neutralDark2};
        border-radius: 50%;
        position: absolute;
        top: 0.9375rem;
        left: 0;
      }
    }
  }

  @media (max-width: 1023px) {
    font-size: 1rem;
    line-height: 2rem;

    ul {
      li {
        &:before {
          top: 0.875rem;
        }
      }
    }
  }
`
